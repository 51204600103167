import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';

const ReadableFont = ({ isFilterActive, toggleOption }) => {
	const handleFont = () => {
		if (!isFilterActive) {
			document.querySelectorAll('#root *').forEach((element) => {
				element.style.fontFamily = 'Verdana,Arial,Helvetica,sans-serif';
			});
		}
		toggleOption();
	};
	return (
		<ListItem
			className='accessiblility-links'
			tabIndex={1}
			sx={{
				padding: '2px 18px',
				cursor: 'pointer',
				background: isFilterActive
					? (theme) => theme.palette.common.accessibility
					: (theme) => theme.palette.common.white,
				color: isFilterActive
					? (theme) => theme.palette.common.white
					: 'inherit'
			}}
			onClick={handleFont}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleFont();
				}
			}}
		>
			<FormatSizeOutlinedIcon
				sx={{ fontSize: '14px', verticalAlign: 'middle', marginRight: '5px' }}
			/>
			<ListItemText primary='Readable Font' primaryTypographyProps={{ sx: { fontSize: '14px' } }} />
		</ListItem>
	);
};

ReadableFont.propTypes = {
	isFilterActive: PropTypes.bool,
	toggleOption: PropTypes.func
};

export default ReadableFont;
