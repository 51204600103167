import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import LinkIcon from '@mui/icons-material/Link';

const LinksUnderline = ({ isFilterActive, toggleOption }) => {
	const handleLinksUnderline = () => {
		if (!isFilterActive) {
			document
				.querySelectorAll('a, button, .MuiAccordionSummary-content, .accessiblility-links')
				.forEach((link) => {
					link.style.textDecoration = 'underline';
				});
		}
		toggleOption();
	};
	return (
		<ListItem
			className='accessiblility-links'
			tabIndex={1}
			sx={{
				padding: '2px 18px',
				cursor: 'pointer',
				background: isFilterActive
					? (theme) => theme.palette.common.accessibility
					: (theme) => theme.palette.common.white,
				color: isFilterActive
					? (theme) => theme.palette.common.white
					: 'inherit'
			}}
			onClick={handleLinksUnderline}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleLinksUnderline();
				}
			}}
		>
			<LinkIcon sx={{ fontSize: '14px', verticalAlign: 'middle', marginRight: '5px' }} />
			<ListItemText primary='Links Underline' primaryTypographyProps={{ sx: { fontSize: '14px' } }} />
		</ListItem>
	);
};

LinksUnderline.propTypes = {
	isFilterActive: PropTypes.bool,
	toggleOption: PropTypes.func
};

export default LinksUnderline;
