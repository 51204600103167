import React from 'react';
import { Outlet } from 'react-router-dom';

import Accessibility from '../AccessibilityTool';
import Header from './Header';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Footer from './Footer';
import ContentFooter from './ContentFooter';

const Layout = () => {
	return (
		<React.Fragment>
			<Container className='filter content'>
			<Container
				sx={{
					backgroundColor: (theme) => theme.palette.common.white
				}}
			>
				<Header />
				<Box
					component='main'
					sx={{ backgroundColor: 'white', padding: '15px 25px' }}
				>
					<Outlet />
					<ContentFooter />
				</Box>
			</Container>
			<Container>
				<Footer />
			</Container>
			</Container>
			<Accessibility />
		</React.Fragment>
	);
};

export default Layout;
