import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';

const LightBackground = ({ isFilterActive, toggleOption }) => {
	const handleLightBackground = () => {
		if (!isFilterActive) {
			document.querySelectorAll('.filter').forEach((element) => {
				element.style.filter = '';
			});
			document.querySelector('body').style.background = '';
			document.querySelectorAll('section, .filter').forEach((element) => {
				element.style.background = 'white';
			});
		}

		toggleOption();
	};
	return (
		<ListItem
			className='accessiblility-links'
			tabIndex={1}
			sx={{
				padding: '2px 18px',
				cursor: 'pointer',
				background: isFilterActive
					? (theme) => theme.palette.common.accessibility
					: (theme) => theme.palette.common.white,
				color: isFilterActive
					? (theme) => theme.palette.common.white
					: 'inherit'
			}}
			onClick={handleLightBackground}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleLightBackground();
				}
			}}
		>
			<EmojiObjectsOutlinedIcon
				sx={{ fontSize: '14px', verticalAlign: 'middle', marginRight: '5px' }}
			/>
			<ListItemText primary='Light Background' primaryTypographyProps={{ sx: { fontSize: '14px' } }} />
		</ListItem>
	);
};

LightBackground.propTypes = {
	isFilterActive: PropTypes.bool,
	toggleOption: PropTypes.func
};

export default LightBackground;
