import { createTheme } from '@mui/material/styles';

const error = '#DB0000';
const warning = '#f4bb00';
const success = '#699E00';
const white = '#ffffff';
const black = '#000000';

const lightTheme = {
	typography: {
		fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
		fontWeight: 600
	},

	palette: {
		type: 'light',
		background: {
			default: '#efefef'
		},
		primary: {
			main: '#747676'
		},
		secondary: {
			main: '#949149'
		},
		link: {
			main: '#4B7000'
		},
		common: {
			black,
			white,
			offWhite: '#f5f6f7',
			accessibility: '#005abb'
		},

		error: {
			main: error
		},
		success: {
			main: success
		},
		warning: {
			main: warning
		},
		information: {
			main: '#045eda'
		}
	}
};

export default createTheme(lightTheme);
