import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';

const Grayscale = ({ isFilterActive, toggleOption }) => {
	const handleGrayscale = () => {
		if (!isFilterActive) {
			document.querySelectorAll('.filter').forEach((element) => {
				element.style.filter = 'grayscale(100%)';
			});
			document.querySelector('body').style.background = '';
		}

		toggleOption();
	};
	return (
		<ListItem
			className='accessiblility-links'
			tabIndex={1}
			sx={{
				padding: '2px 18px',
				cursor: 'pointer',
				background: isFilterActive
					? (theme) => theme.palette.common.accessibility
					: (theme) => theme.palette.common.white,
				color: isFilterActive
					? (theme) => theme.palette.common.white
					: (theme) => theme.palette.common.black
			}}
			onClick={handleGrayscale}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleGrayscale();
				}
			}}
		>
			<CalendarViewWeekOutlinedIcon
				sx={{ fontSize: '14px', verticalAlign: 'middle', marginRight: '5px' }}
			/>
			<ListItemText primary='Grayscale' primaryTypographyProps={{ sx: { fontSize: '14px' } }} />
		</ListItem>
	);
};

Grayscale.propTypes = {
	isFilterActive: PropTypes.bool,
	toggleOption: PropTypes.func
};

export default Grayscale;
