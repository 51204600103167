import React, { useState } from 'react';

import Grayscale from './Grayscale';
import HighContrast from './HighContrast';
import NegativeContrast from './NegativeContrast';
import LightBackground from './LightBackground';
import LinksUnderline from './LinksUnderline';
import ReadableFont from './ReadableFont';
import Reset from './Reset';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import AccessibilityIcon from '@mui/icons-material/Accessibility';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: theme.palette.common.accessibility,
	borderRadius: 0,
	color: theme.palette.common.white,
	width: '50px',
	height: '50px',
	'&:hover': { background: theme.palette.common.accessibility }
}));

const AccessibilityTools = () => {
	const [toolbarOpen, setToolbarOpen] = useState(false);
	const [activeFilter, setActiveFilter] = useState(null);

	const handleFilterToggle = (option) => {
		if (activeFilter === option) {
			setActiveFilter(null);
			document.querySelectorAll('.filter').forEach((element) => {
				element.style.filter = 'none';
			});
			document
				.querySelectorAll('section, .filter, header')
				.forEach((element) => {
					element.style.backgroundColor = 'white';
				});

			document.querySelector('body').style.background = '';
			document.querySelectorAll('#root *').forEach((element) => {
				element.style.fontFamily = '';
			});
			document
				.querySelectorAll(
					'.MuiTypography-root, .MuiTypography-root *, .accessiblility-links, .MuiIconButton-root'
				)
				.forEach((element) => {
					element.style.color = '';
				});
			document
				.querySelectorAll('a, button, .MuiAccordionSummary-content, .accessiblility-links')
				.forEach((link) => {
					link.style.textDecoration = '';
				});
		} else {
			setActiveFilter(option);
		}
	};

	const toggleDropdown = () => {
		setToolbarOpen(!toolbarOpen);
	};

	return (
		<>
			<Stack
				className='accessibility'
				flexDirection='row'
				sx={{
					position: 'fixed',
					bottom: { xs: '10vh', md: '20vh' },
					right: '0px',
					zIndex: 1000,
					display: 'flex',
					alignItems: 'end',
					cursor: 'pointer'
				}}
			>
				<StyledIconButton
					onClick={toggleDropdown}
					onKeyDown={(event) => {
						toggleDropdown();
					}}
					tabIndex={1}
					title='Accessibility Tools'
					aria-label='Accessibility Tools'
				>
					<AccessibilityIcon />
				</StyledIconButton>

				{toolbarOpen && (
					<Stack
						className='filter'
						sx={{
							backgroundColor: (theme) => theme.palette.common.white,
							border: '1px solid rgba(0,0,0,0.2)',
							padding: '10px 0px',
							minWidth: '200px'
						}}
					>
						<Typography
							variant='h4'
							className='MuiTypography-root'
							sx={{ margin: '5px 0 12px', fontSize: '16px', fontWeight: 600, paddingLeft: '18px' }}
						>
							Accessibility Tools
						</Typography>
						<List
							sx={{
								padding: 0
							}}
						>
							<Grayscale
								isFilterActive={activeFilter === 'grayscale'}
								toggleOption={() => handleFilterToggle('grayscale')}
							/>
							<HighContrast
								isFilterActive={activeFilter === 'highContrast'}
								toggleOption={() => handleFilterToggle('highContrast')}
							/>
							<NegativeContrast
								isFilterActive={activeFilter === 'negativeContrast'}
								toggleOption={() => handleFilterToggle('negativeContrast')}
							/>
							<LightBackground
								isFilterActive={activeFilter === 'lightBackground'}
								toggleOption={() => handleFilterToggle('lightBackground')}
							/>
							<LinksUnderline
								isFilterActive={activeFilter === 'linksUnderline'}
								toggleOption={() => handleFilterToggle('linksUnderline')}
							/>
							<ReadableFont
								isFilterActive={activeFilter === 'readableFont'}
								toggleOption={() => handleFilterToggle('readableFont')}
							/>
							<Reset setActiveFilter={setActiveFilter} />
						</List>
					</Stack>
				)}
			</Stack>
		</>
	);
};

export default AccessibilityTools;
